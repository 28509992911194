<template>
  <v-footer
    id="home-app-footer"
    dark
    padless
  >
    <v-card
      flat
      tile
      class="accent white--text"
      width="100%"
    >
      <v-card-text>
        <v-container fluid grid-list-md>
            <v-layout flex-row wrap>
                <v-flex d-flex xs12 sm6 md4 justify-center align-center mb-4>
                    <div>
                        <v-img
                            src="../../assets/logo.png"
                            width="50%"
                            class="mx-auto logo-footer"
                        />
                    </div>
                </v-flex>
                <v-flex d-flex xs12 sm6 md4 align-center flex-column class="justify-sm-center mb-3 tx-align-md shape-footer">
                    <div class="line-shape">
                        <a href="mailto:fresar@fresaringenieros.co" class="line-email text-decoration-none t-decor-footer text-lg-subtitle-1 text-md-body-1 ">fresar@fresaringenieros.co</a>
                        <div class="line"></div>
                    </div>
                    <div 
                        v-for="(contact, i) in contacts"
                        :key="i"
                        class="white--text d-flex flex-row w-contact line-shape"
                    >
                        <div class="w-type-contact">
                            <span class="text-lg-subtitle-1 text-md-body-1">{{ contact.type }} </span>
                        </div>
                        <div class="">
                            <a :href="contact.url" class="text-decoration-none t-decor-footer text-lg-subtitle-1 text-md-body-1">
                                {{ contact.info }}
                            </a>
                        </div>
                    </div>                   
                </v-flex>
                <v-flex d-flex xs12 md4 justify-center align-center>
                    <p class="white--text text-lg-subtitle-1 text-md-body-1">Calle 86 No. 24 – 45 Bogotá, Colombia</p>              
                </v-flex>
            </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class=" py-2 white--text text-center">
        {{ new Date().getFullYear() }} |
        <span> Powered by 
            <a
            href="https://enalgunlugarestudio.com" target="_blank"
            class="text-decoration-none t-decor-footer text-lg-subtitle-1 text-md-body-1">
                En Algun Lugar Estudio
            </a>
        </span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
    name: 'Footer',

    data() {
        return {
            contacts: [
                {
                    type: 'Tel:',
                    info: '(571) 691 5658',
                    url: 'tel:+5716915658'
                },
                {
                    type: 'Móvil:',
                    info: '(57) 310 212 1291',
                    url: 'tel:+573102121291'
                }
            ],
        }
    },
}
</script>

<style lang="scss">
#home-app-footer {
    bottom: 0;
    left: 0;
    width: 100%;

    .line-shape {
        width: 100%;
        .line {
            width: 16%;
            border-bottom: 1px solid #F35E22;
            margin-bottom: 1vw;
            padding-top: 4px;
        }
    }

    .w-contact {
        width: 100%;
        .w-type-contact {
            width: 15%;
        }
    }
    a.t-decor-footer {
        color: white;
            &:hover {
                color: #F35E22;
            }
    }
    .logo-footer { max-height: 80px; }

    @media (min-width: 1920px) {
        .line-shape {
            .line { width: 10%; }
        }
    }

    @media (max-width: 960px) {
        .tx-align-md { margin-left: 21vw; }
    }

    @media (min-width: 401px) and (max-width: 600px) {
        .w-contact {
            .w-type-contact { width: 20%; }
        }
    }
}
</style>